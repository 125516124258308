import { useEffect, useState } from "react";
import Slider from "react-slick";
import { ClientOnly } from "remix-utils/client-only";
import Container from "~/components/layout/Container";
import { Icon } from "~/components/ui/icon";

const references = [
  { name: "Voyager", logo: "/assets/referans-Voyager.webp" },
  { name: "Yumak", logo: "/assets/referans-Yumak.webp" },
  { name: "MoonLift", logo: "/assets/referans-MoonLift.webp" },
  { name: "CNR", logo: "/assets/referans-CNR.webp" },
  { name: "Fobex", logo: "/assets/referans-Fobex.webp" },
];

export const Dots = ({ dots }: any) => {
  return (
    <div className={Style.Dots}><ul> {dots} </ul></div>
  )
}
const Style = {
  Dots: `absolute -bottom-4 right-0 left-0 m-auto flex justify-center w-full
    [&_ul]:bottom-0 [&_ul]:right-5 [&_ul]:flex [&_ul]:w-fit
    [&_button]:w-4 [&_button]:h-4 [&_button]:mr-1 [&_button]:relative [&_button]:!text-[0px] [&_button]:cursor-pointer [&_button]:transition-0.6s [&_button]:border-2 [&_button]:border-[#20b1f5] [&_button]:shadow [&_button]:transition-[0.6s]
    [&_button]:focus:outline-none 
    [&_.slick-active_button]:border-2 [&_.slick-active_button]:border-[#144e85] [&_.slick-active_button]:w-3 [&_.slick-active_button]:h-3 [&_.slick-active_button]:mt-[-2px] [&_.slick-active_button]:transition-[0.6s]`,
  NextArrow: `absolute -bottom-6 lg:bottom-[45%] right-0 lg:-right-10 left-auto z-10 text-0 p-1 border-none cursor-pointer transition-0.5s bg-transparent
    [&_svg]:w-8 [&_svg]:h-8 [&_svg]:text-[#20b1f5]
    hover:bg-[#ffffff55]
    focus:outline-none`,
  PrevArrow: `absolute -bottom-6 lg:bottom-[45%] left-0 lg:-left-10 right-auto z-10 text-0 p-1 border-none cursor-pointer transition-0.5s bg-transparent
    [&_svg]:w-8 [&_svg]:h-8 [&_svg]:text-[#20b1f5]
    hover:bg-[#ffffff55]
    focus:outline-none`,
}

export const ArrowPrev = ({ ...props }: any) => {
  return (
    <button {...props} className={Style.PrevArrow}>
      <Icon name="ic:outline-keyboard-arrow-left" />
    </button>
  )
}

export const ArrowNext = ({ ...props }: any) => {
  return (
    <button {...props} className={Style.NextArrow}>
      <Icon name="material-symbols:chevron-right" />
    </button>
  )
}
export default function References() {
  const [mounted, setMounted] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setMounted(true);
    setIsMobile(window.innerWidth < 768);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: isMobile ? 3 : 5,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <ArrowPrev />,
    nextArrow: <ArrowNext />,
    appendDots: (dots: any) => <Dots dots={dots} />,
  };

  if (!mounted) { return null; }

  return (
    <section className="py-0 md:py-16 mb-28 md:mb-0">
      <Container className="flex justify-between">
        <div className="block justify-center w-full [&_.slick-track]:!flex [&_.slick-track]:!w-fit">

          <ClientOnly fallback={<div>Loading...</div>}>
            {() => (
              <Slider {...settings}>
                {references.map((ref) => (
                  <div key={ref.name} className="w-[calc(100%/5-20px)] h-40 p-2 flex items-center justify-center">
                    <img src={ref.logo} alt={ref.name} className="w-full h-full max-h-40 object-contain flex items-center justify-center" />
                  </div>
                ))}
              </Slider>
            )}
          </ClientOnly>
        </div>
      </Container>
    </section>
  );
}