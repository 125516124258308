import { useEffect, useState } from "react";
import Slider from "react-slick";
import { ClientOnly } from "remix-utils/client-only";
import Container from "~/components/layout/Container";
import { Icon } from "~/components/ui/icon";

const slides = [
  {
    image: "/assets/Utb-trader-support-main-slider-1.webp",
    title: "İhracatta Rekabet Gücü Kazanın!",
    subTitle: "Ürün İhracatında Fark Yaratın",
    description: "UTB Support ile ürünlerinizin uluslararası pazarda rekabetçiliğini artırarak, hedef pazarları tespit edin ve stratejik hamleler yapın.",
    cta: "TEKLİF AL",
  }, {
    image: "/assets/Utb-trader-support-main-slider-2.webp",
    title: "Doğru Hedef Pazara Ulaşın",
    subTitle: "İhracat Hedef Pazar Analizi",
    description: "Hedef pazarınızı UTB Support danışmanlık hizmeti ile belirleyin ve ürünlerinizi doğru pazarlara en etkin şekilde sunun.",
    cta: "TEKLİF AL",
  }, {
    image: "/assets/Utb-trader-support-main-slider-3.webp",
    title: "Lojistik ve Gümrük Süreçlerinde Ustalaşın",
    subTitle: "Lojistik & Gümrük Operasyonları Eğitimi",
    description: "Lojistik ve gümrük operasyonlarını en etkili şekilde yönetmek için uzman eğitimlerimizle başarıya ulaşın.",
    cta: "TEKLİF AL",
  }, {
    image: "/assets/Utb-trader-support-main-slider-4.webp",
    title: "Zamandan Tasarruf Edin",
    subTitle: "Hedef Müşterilerinize Hızla Ulaşın",
    description: "UTB Support ile ihracat hedef müşterilerinizi hızlıca tespit edin ve doğrudan onlara ulaşarak zaman ve kaynaklardan tasarruf edin.",
    cta: "TÜM HİZMETLERİMİZ",
  },
];

export const Dots = ({ dots }: any) => {
  return (
    <div className={Style.Dots}><ul> {dots} </ul></div>
  )
}
const Style = {
  Dots: `absolute bottom-4 right-0 left-0 m-auto flex justify-center w-full
    [&_ul]:bottom-0 [&_ul]:right-5 [&_ul]:flex [&_ul]:w-fit
    [&_button]:w-4 [&_button]:h-4 [&_button]:mr-1 [&_button]:relative [&_button]:!text-[0px] [&_button]:cursor-pointer [&_button]:transition-0.6s [&_button]:border-2 [&_button]:border-[#20b1f5] [&_button]:shadow [&_button]:transition-[0.6s]
    [&_button]:focus:outline-none 
    [&_.slick-active_button]:border-2 [&_.slick-active_button]:border-white [&_.slick-active_button]:w-3 [&_.slick-active_button]:h-3 [&_.slick-active_button]:mt-[-2px] [&_.slick-active_button]:transition-[0.6s]`,
  NextArrow: `absolute bottom-2 md:bottom-[45%] right-1 md:right-3 left-auto z-10 text-0 p-2 md:p-4 border-none cursor-pointer transition-0.5s bg-[#00000099] md:bg-[#ffffff22]
    [&_svg]:w-5 [&_svg]:h-5 [&_svg]:text-white
    hover:bg-[#ffffff55]
    focus:outline-none`,
  PrevArrow: `absolute bottom-2 md:bottom-[45%] left-1 md:left-3 right-auto z-10 text-0 p-2 md:p-4 border-none cursor-pointer transition-0.5s bg-[#00000099] md:bg-[#ffffff22]
    [&_svg]:w-5 [&_svg]:h-5 [&_svg]:text-white
    hover:bg-[#ffffff55]
    focus:outline-none`,
}

export const ArrowPrev = ({ ...props }: any) => {
  return (
    <button {...props} className={Style.PrevArrow}>
      <Icon name="ic:outline-keyboard-arrow-left" />
    </button>
  )
}

export const ArrowNext = ({ ...props }: any) => {
  return (
    <button {...props} className={Style.NextArrow}>
      <Icon name="material-symbols:chevron-right" />
    </button>
  )
}

export default function ImageCarousel() {
  const [mounted, setMounted] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setMounted(true);
    setIsMobile(window.innerWidth < 768);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: isMobile ? 10000 : 8000,
    arrows: true,
    waitForAnimate: false,
    prevArrow: <ArrowPrev />,
    nextArrow: <ArrowNext />,
    appendDots: (dots: any) => <Dots dots={dots} />,
  };

  if (!mounted) { return null; }
  return (
    <div className="relative z-0 mt-[110px] md:mt-0">
      <ClientOnly fallback={<div>Loading...</div>}>
        {() => (
          <Slider {...settings} className="relative z-0">
            {slides.map((slide, index) => (
              <div key={slide.image} className="relative">
                <img src={slide.image} alt={slide.title} className="w-[130%]  max-w-[130%] h-auto" />
                <div className="absolute z-10 border-2 border-white inset-0 bg-opacity-50 flex items-start md:items-center justify-start">
                  <Container>
                    <div className="text-white font-raleway">
                      <h3 className="font-medium mb-0.5 md:mb-8 text-[18px] md:text-[34px]">{slide.subTitle}</h3>
                      <h2 className="font-bold mb-0.5 md:mb-8 text-[16px] md:text-[48px]">{slide.title}</h2>
                      <p className="mb-2 md:mb-8 w-full max-w-[600px] text-[14px] md:text-[22px]">{slide.description}</p>
                      <a href="/iletisim" className="bg-[#15437b] border-2 border-white hover:bg-sky-600 text-white font-bold py-2 px-4 rounded">
                        {slide.cta}
                      </a>
                    </div>
                  </Container>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </ClientOnly>
    </div>
  );
}