export const slugify = (str: string) => {
  return str
    .toLowerCase()
    .replaceAll('ğ', 'g')
    .replaceAll('ç', 'c')
    .replaceAll('ö', 'o')
    .replaceAll('ü', 'u')
    .replaceAll('ı', 'i')
    .replaceAll('ş', 's')
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-')
}
