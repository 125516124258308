import Container from "~/components/layout/Container";

export default function OfferBanner() {
  return (
    <section className="bg-[#f5f5f5] text-[#444444] py-12">
      <Container className="justify-center flex-wrap">
        <h2 className="text-[26px] font-bold mb-4 flex flex-wrap md:flex-nowrap justify-center w-full">HİZMETLERİMİZ İÇİN <span className="text-[#20b1d7] ml-2">BİZE ULAŞIN!</span></h2>
        <p className="flex text-center w-full justify-center flex-col text-[22px] !font-[300] !font-lato text-[#555555] mb-6 after:content-[''] after:block after:w-10 after:h-[2px] after:m-auto after:bg-[#16437c] after:mt-4">Teklif almak ve hizmetlerimiz ile alakalı bilgi alamak için teklif formumuzu doldurarak bize ulaşabilirsiniz.</p>
        <a href='/hizmetlerimiz' className="border border-[#16437c] text-[#16437c] hover:bg-[#16437c] hover:text-white px-4 py-2 rounded-md">
          Teklif Al
        </a>
      </Container>
    </section>
  );
}