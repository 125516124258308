import Container from "~/components/layout/Container";
import type { servicesData } from "../hizmetlerimiz+/features/hizmetlerData";



export const Services = ({ hizmetler, title, header }: { hizmetler: typeof servicesData, title?: string, header?: string }) => {
  if (header === null) {
    header = 'HİZMETLERİMİZ';
  }

  return (
    <div className="py-20" id="hizmetlerimiz">
      <Container className="flex-wrap !p-0">
        <h2 className="text-3xl w-full justify-center font-black font-raleway text-center text-[32px] mb-5">{header}</h2>
        <p className="text-center text-[24px] mb-12 font-lato text-[#444444] relative font-[300] py-12 after:content-[''] after:absolute after:bottom-0 after:left-0 after:right-0 after:m-auto after:w-20 after:h-0.5 after:bg-[#20b1d7] [&_i]:font-thin [&_i]:not-italic [&_i]:font-lato" dangerouslySetInnerHTML={{ __html: title ?? '' }} />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {hizmetler.map((service) => (
            <a key={service.title} href={`/hizmetlerimiz/detay/${service.id}`} className="w-full bg-white group mb-8 overflow-hidden">
              <img src={service.image} alt={service.title} className="group-hover:shadow-lg w-full h-48 object-cover" />
              <div className="p-5">
                <h3 className="text-xl font-black font-raleway text-[16px] text-[#16437c]">{service.title}</h3>
                <p className="mt-2 text-[14px] font-lato text-[#333333]" dangerouslySetInnerHTML={{ __html: service.description }} />
              </div>
            </a>
          ))}
        </div>
      </Container>
    </div>
  );
};