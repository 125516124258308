export function toISOStringWithTimezone(
  date: Date,
  timezone = 'Europe/Istanbul',
) {
  const dateTimeString = new Intl.DateTimeFormat('en-US', {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    fractionalSecondDigits: 3,
  }).format(date)
  // console.log(dateTimeString)
  const [datePart, timePart] = dateTimeString.split(', ')
  const [month, day, year] = datePart.split('/')
  return `${year}-${month}-${day}T${timePart}Z`
}

/**
 * Converts a datetime string from a specified timezone to a JavaScript Date object in UTC.
 * @param dateTimeString - The datetime string formatted as 'YYYY-MM-DDTHH:mm:ss.sss'
 * @param timezone - The timezone in which the dateTimeString is considered to be.
 */
export function fromTimezoneToUTC(
  dateTimeString: string,
  timezone = 'Europe/Istanbul',
): Date {
  // As the input might implicitly consider 'Z', we ensure it's treated as local time by removing 'Z' if present.
  const fixedString = dateTimeString.replace('Z', '')

  // Parse the local time string as if it is in the given timezone
  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    fractionalSecondDigits: 3,
    // timeZone: 'UTC',
    timeZone: timezone,
  })

  // This step corrects for the timezone offset:
  // It creates a Date object which represents the local time described by fixedString as if it was UTC.
  const dateInLocalTime = new Date(fixedString)

  // Format this date as a UTC string then convert to Date object to ensure UTC consistency
  const formattedUTCString = formatter.format(dateInLocalTime)
  const utcDateObject = new Date(formattedUTCString)

  return utcDateObject
}

if (import.meta.vitest) {
  const { test, expect } = import.meta.vitest

  test('toISOStringWithTimezone', () => {
    const date = new Date()
    const timezone = 'America/New_York'

    // console.log(toISOStringWithTimezone(date, timezone))
    // console.log(toISOStringWithTimezone(date))
    // console.log(toISOStringWithTimezone(date, 'UTC'))
    // console.log(date.toISOString())

    console.log(date)
    console.log(toISOStringWithTimezone(date))

    const dateString = toISOStringWithTimezone(date)

    console.log(dateString)
    console.log(fromTimezoneToUTC(dateString))

    expect(toISOStringWithTimezone(date, 'UTC')).toBe(date.toISOString())
  })
}

export function toLocaleDateString(
  date: Date,
  locale = 'tr-TR',
  options: Intl.DateTimeFormatOptions = {
    year: '2-digit',
    month: 'short',
    day: '2-digit',
  },
) {
  return date.toLocaleDateString(locale, options)
}
export function toLocaleTimeString(
  date: Date,
  locale = 'tr-TR',
  options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  },
) {
  return date.toLocaleTimeString(locale, options)
}
export function toLocaleCurrencyString(
  value: number,
  locale = 'tr-TR',
  currency = 'TRY',
) {
  return value.toLocaleString(locale, { style: 'currency', currency })
}
