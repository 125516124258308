import { useEffect, useState } from "react";
import Slider from "react-slick";
import { ClientOnly } from "remix-utils/client-only";
import Container from "~/components/layout/Container";
import { Card, CardContent, CardHeader, CardTitle } from "~/components/ui/card";
import { Icon } from "~/components/ui/icon";
import { slugify } from "~/lib/slugify";
import { toLocaleDateString } from "~/utils/date";


export const blogPosts = [
  {
    title: "Türkiye Bursları",
    excerpt: "Türkiye, uluslararası öğrencilere eğitim imkanları sunan birçok burs programına ev sahipliği yapmaktadır. Türkiye bursları, yabancı öğrencilerin Türkiye'deki ...",
    description: [
      'Türkiye, uluslararası öğrencilere eğitim imkanları sunan birçok burs programına ev sahipliği yapmaktadır. Türkiye bursları, yabancı öğrencilerin Türkiye\'deki üniversitelerde lisans, yüksek lisans veya doktora programlarına katılmalarını sağlamayı amaçlamaktadır. Bu burslar, Türkiye\'nin yüksek kaliteli eğitim sistemine erişim sağlamak ve kültürel deneyim kazanmak isteyen uluslararası öğrencilere destek olmayı hedeflemektedir.',
      'Türkiye burs programlarının en tanınmışı Türkiye Bursları\'dır. Türkiye Bursları, Türkiye Cumhuriyeti hükümeti tarafından sunulan tam burslu bir programdır. Bu program kapsamında uluslararası öğrencilere tam zamanlı lisans, yüksek lisans ve doktora programları için burs imkanı sağlanmaktadır. Türkiye Bursları, başarılı öğrencilere sağlanan birçok avantaj sunmaktadır. Bunlar arasında eğitim ücretinin tamamen karşılanması, aylık yaşam masrafı desteği, sağlık sigortası, Türkçe hazırlık programı gibi imkanlar yer almaktadır. Türkiye Bursları, başvuruları uluslararası öğrencilerin çeşitli ülkelerdeki Türk büyükelçilikleri veya konsoloslukları aracılığıyla kabul etmektedir.',
      'Diğer bir burs programı ise Türkiye Eğitim Bakanlığı tarafından yürütülen Türkiye Yükseköğretim Burs Programı\'dır. Bu program, Türkiye\'de lisans veya yüksek lisans programlarına başvuran uluslararası öğrencilere burs imkanı sunmaktadır. Bu burs programında başarılı öğrencilere eğitim ücretinin tamamen karşılanması, aylık yaşam masrafı desteği, sağlık sigortası gibi avantajlar sağlanmaktadır.',
      'Türkiye\'nin burs programlarına başvuruda bulunacak adaylardan genellikle akademik başarı, dil yeterliliği ve motivasyon mektubu gibi kriterler aranmaktadır. Türkiye\'nin üniversitelerinde eğitim almak isteyen uluslararası öğrenciler, başvuru süreci ve gereklilikler hakkında detaylı bilgiyi Türkiye\'nin yurtdışındaki temsilciliklerinden veya burs programlarının resmi web sitelerinden edinebilirler.',
      'Türkiye bursları, yabancı öğrencilere Türk kültürünü ve eğitim sistemini yakından tanıma fırsatı sunmaktadır. Türkiye\'nin tarihi ve kültürel zenginlikleriyle birleşen modern eğitim olanakları, uluslararası öğrenciler için cazip bir tercih haline gelmektedir. Ayrıca Türkiye\'nin stratejik konumu, uluslararası öğrencilere Avrupa, Asya ve Ortadoğu\'ya kolay erişim imkanı sağlamaktadır.',
      'Sonuç olarak, Türkiye bursları uluslararası öğrencilere geniş bir yelpazede eğitim imkanları sunan çeşitli programlardır. Bu burslar, yabancı öğrencilerin Türkiye\'de kaliteli bir eğitim almasını ve Türk kültürünü deneyimlemesini sağlamaktadır. Türkiye\'nin burs programlarına başvurarak uluslararası bir eğitim deneyimi elde etmek isteyen öğrenciler, burs başvurularının takvimini ve gerekliliklerini dikkatlice incelemelidirler.'
    ],
    date: "2023-05-15",
    image: "/assets/Turkiye-Burslari.webp",
  }, {
    title: "Türkiye’de Dış Ticaret Personeli Olarak Çalışmak",
    excerpt: "Dış ticaret, ülkeler arasında mal ve hizmetlerin alımı ve satımını kapsayan bir faaliyettir. Türkiye, coğrafi konumu ve ekonomik potansiyeli nedeniyle dış ticarette ...",
    description: [
      "Dış ticaret, ülkeler arasında mal ve hizmetlerin alımı ve satımını kapsayan bir faaliyettir. Türkiye, coğrafi konumu ve ekonomik potansiyeli nedeniyle dış ticarette önemli bir rol oynamaktadır. Bu nedenle, Türkiye'de dış ticaret personeli olarak çalışmak isteyenler için birçok fırsat bulunmaktadır.",
      "Dış ticaret personeli olarak çalışmak, ihracat ve ithalat işlemlerini yönetmek, uluslararası ticaret anlaşmalarını takip etmek, lojistik düzenlemeleri yapmak, gümrük mevzuatını uygulamak gibi görevleri içerir. Türkiye'de dış ticaret alanında kariyer yapmak isteyenler için, şirketlerin dış ticaret departmanlarında veya özel dış ticaret firmalarında çalışma imkanları bulunmaktadır. Ayrıca, Türkiye'nin büyük limanları ve serbest ticaret bölgeleri gibi stratejik noktalarda dış ticaret operasyonlarını yöneten lojistik şirketlerinde de istihdam edilebilirler.",
      "Dış ticaret personeli olarak çalışmak için bazı önemli becerilere sahip olmak gerekmektedir. İyi derecede İngilizce ve tercihen başka bir yabancı dil bilmek, uluslararası ticaret süreçlerini anlamak ve yönetmek için önemlidir. Ayrıca, gümrük mevzuatı, taşımacılık ve lojistik konularında bilgi sahibi olmak da dış ticaret personeli için önemli bir avantajdır. İyi iletişim, takım çalışması, analitik düşünme ve problem çözme becerileri de başarılı bir dış ticaret personelinin sahip olması gereken nitelikler arasındadır.",
      "Türkiye'de dış ticaret personeli olarak çalışmak için çeşitli kariyer olanakları bulunmaktadır. İş ilanları, iş bulma platformları, şirketlerin kariyer sayfaları ve meslek odaları gibi kaynaklardan takip edilebilir. Ayrıca, üniversitelerin dış ticaret, lojistik veya uluslararası ticaret gibi bölümlerinden mezun olanlar, staj programlarına katılarak sektör deneyimi kazanabilir ve iş bulma şanslarını artırabilirler.",
      "Sonuç olarak, Türkiye'de dış ticaret personeli olarak çalışmak, uluslararası ticaretin dinamik bir ortamında yer almayı ve küresel ekonomiye katkıda bulunmayı sağlayan heyecan verici bir kariyer seçeneğidir. Dış ticaret personeli olarak çalışmak isteyenlerin sektörü yakından takip etmeleri, gelişmeleri izlemeleri ve gerekli becerileri edinmeleri önemlidir. Türkiye'nin dış ticaret potansiyeli ve uluslararası ilişkiler ağı, dış ticaret personeli olarak çalışmak isteyenler için çeşitli fırsatlar sunmaktadır."
    ],
    date: "2023-05-15",
    image: "/assets/Turkiyede-Dis-Ticaret-Personeli-Olarak-Calismak.webp",
  }, {
    title: "Yabancılar İçin Türkiye'de Üniversite Öğrenimi ve Türk Üniversiteleri",
    excerpt: "Türkiye, yabancı öğrencilere açık ve misafirperver bir ülke olup, yabancıların üniversite öğrenimi görmesi için çeşitli imkânlar sunmaktadır. Türk üniversiteleri, uluslararası ...",
    description: [
      'Türkiye, yabancı öğrencilere açık ve misafirperver bir ülke olup, yabancıların üniversite öğrenimi görmesi için çeşitli imkânlar sunmaktadır. Türk üniversiteleri, uluslararası öğrencilerin Türkiye\'de eğitim almaları için geniş bir yelpazede programlar sunar ve kültürel çeşitlilik ve küresel işbirliği ortamı sağlar.',
      'Türk üniversiteleri, kaliteli eğitim standartlarına sahip ve dünya genelinde tanınan kurumlar arasında yer alır. Yabancı öğrenciler, Türk üniversitelerinde lisans, yüksek lisans ve doktora programlarına başvurabilirler. Üniversitelerde İngilizce ve Türkçe olmak üzere farklı dil seçenekleri sunulurken, bazı üniversiteler tamamen İngilizce eğitim veren programlara da sahiptir.',
      'Türk üniversiteleri, geniş bir akademik yelpazede eğitim imkânı sunar. Mühendislik, tıp, işletme, hukuk, sosyal bilimler, fen bilimleri, güzel sanatlar ve iletişim gibi çeşitli alanlarda programlar bulunur. Ayrıca, Türkiye\'nin zengin tarih ve kültürel mirasıyla bağlantılı olarak arkeoloji, tarih ve sanat gibi alanlarda da özel programlar mevcuttur.',
      'Türk üniversiteleri, uluslararası öğrencilere çeşitli burs ve destek programları da sunar ve https://www.turkiyeburslari.gov.tr/ adresinden daha geniş bilgiler temin edilebilir. Türkiye Bursları gibi hükümet destekli burslar, uluslararası öğrencilerin eğitim masraflarını karşılamak için fırsatlar sunar. Ayrıca, üniversitelerin kendi burs programları, öğrencilere maddi destek sağlamak için kullanılır. Bu burslar, başarı, akademik performans, spor yetenekleri veya diğer özel kriterlere dayalı olarak verilebilir.',
      'Türkiye\'deki üniversiteler, yabancı öğrencilere çeşitli kültürel etkinlikler, sosyal kulüpler ve öğrenci birlikleri aracılığıyla da zengin bir öğrenci yaşamı sunar. Yabancı öğrenciler, Türk kültürünü deneyimleyebilir, yerel öğrencilerle etkileşimde bulunabilir ve çeşitli uluslararası etkinliklere katılma imkânı bulurlar. Bu, uluslararası öğrencilerin Türk toplumuna entegre olmalarını ve unutulmaz bir deneyim yaşamalarını sağlar.',
      'Sonuç olarak, Türkiye\'deki üniversiteler, yabancı öğrencilere açık ve çekici bir eğitim ortamı sunar. Kaliteli eğitim programları, kültürel çeşitlilik, burs olanakları ve zengin öğrenci yaşamı, Türk üniversitelerinin uluslararası öğrenciler için tercih edilen hedefler arasında yer almasını sağlar. Türkiye\'de üniversite öğrenimi gören yabancı öğrenciler, hem akademik hem de kişisel gelişim açısından zengin bir deneyim elde ederler ve uluslararası bir perspektif kazanırlar.'
    ],
    date: "2023-05-15",
    image: "/assets/Yabancilar-Icin-Turkiyede-Universite-Ogrenimi-ve-Turk-Universiteleri.webp",
  },
  {
    title: "Türk İthalatçı Firmalar",
    excerpt: "Türk ithalatçıları, Türkiye'nin dış ticaretinde önemli bir rol oynayan aktörlerdir. Türkiye, ihtiyaç duyduğu hammadde, ara malı ve tüketim malı gibi ürünleri dünya ...",
    description: [
      'Türk ithalatçıları, Türkiye\'nin dış ticaretinde önemli bir rol oynayan aktörlerdir. Türkiye, ihtiyaç duyduğu hammadde, ara malı ve tüketim malı gibi ürünleri dünya genelinden ithal etmektedir. Türk ithalatçıları, ülkenin sanayi üretimini desteklemek, iç talebi karşılamak ve yerli üretim süreçlerini tamamlamak için çeşitli sektörlerde aktif bir şekilde faaliyet göstermektedir.',
      'Türk ithalatçılarının başlıca motivasyonlarından biri, yüksek kaliteli ve rekabetçi fiyatlı ürünleri Türkiye\'ye getirerek yerel talebi karşılamaktır. Türkiye\'nin sanayi ve hizmet sektörleri, ithal edilen hammaddeler, makine ve ekipmanlar gibi ürünlere bağımlıdır. Bu nedenle, Türk ithalatçıları, kaliteli ve uygun maliyetli malzemeleri tedarik etmek için uluslararası pazarlarda arayış içerisindedir.',
      'Türk ithalatçılarının bir diğer önemli özelliği, küresel ticaret ağlarına olan entegrasyonlarıdır. Türkiye, stratejik konumu sayesinde çeşitli ülkelerle ticaret yapma imkanına sahiptir. Türk ithalatçıları, uluslararası ticaret fuarlarına katılarak, iş gezileri düzenleyerek ve diğer ticaret etkinliklerine aktif olarak katılarak küresel pazarlarda işbirlikleri ve bağlantılar kurmaktadır. Bu şekilde, Türk ithalatçıları, yeni ürün kaynaklarına ve tedarikçilere ulaşarak rekabet avantajı elde etmektedir.',
      'Türk ithalatçılarının faaliyetlerini destekleyen bir diğer faktör, Türkiye\'nin gelişmiş lojistik altyapısıdır. Limanlar, havaalanları, demiryolları ve karayolları, Türk ithalatçılarının dünya genelinden ürünleri hızlı ve güvenilir bir şekilde Türkiye\'ye getirmesini sağlamaktadır. Bu sayede, Türk ithalatçıları, taleplerini zamanında karşılayabilir ve tedarik zincirlerini etkin bir şekilde yönetebilir.',
      'Son olarak, Türk ithalatçıları, yerli üretim süreçlerini tamamlamak için teknoloji transferi ve bilgi paylaşımı gibi konulara önem vermektedir. Uluslararası tedarikçilerle yapılan işbirlikleri, Türk şirketlerine yeni teknolojilerin, yeniliklerin ve en iyi uygulamaların aktarılmasını sağlamaktadır. Bu şekilde, Türk ithalatçıları, yerli üretimlerini geliştirerek rekabetçiliklerini artırabilir ve yerel sanayinin büyümesine katkıda bulunabilir.',
      'Türk ithalatçıları, yerel talebi karşılamak, tedarik zincirlerini güçlendirmek ve yerli üretim süreçlerini tamamlamak amacıyla uluslararası ticarette önemli bir rol oynamaktadır. İthalat faaliyetleri, Türkiye\'nin ekonomik büyümesine katkıda bulunmakta ve ülkenin rekabet gücünü artırmaktadır.'
    ],
    date: "2023-04-08",
    image: "/assets/Turk-Ithalatci-Firmalar.webp",
  }, {
    title: "Türk İhracatçı Firmalar",
    excerpt: "Türk ihracatçıları, Türkiye'nin ekonomik büyümesinde ve uluslararası ticarette önemli bir rol oynayan aktörlerdir. Türkiye, stratejik konumu, çeşitlendirilmiş ekonomisi ...",
    description: [
      'Türk ihracatçıları, Türkiye\'nin ekonomik büyümesinde ve uluslararası ticarette önemli bir rol oynayan aktörlerdir. Türkiye, stratejik konumu, çeşitlendirilmiş ekonomisi ve rekabetçi üretim kapasitesiyle dünya genelinde tanınan bir ihracat ülkesidir. İhracatçılar, ülkenin dış ticaret fazlasını artırarak ekonomik kalkınmayı destekler ve Türk mal ve hizmetlerinin dünya pazarlarında rekabetçi olmasını sağlar.',
      'Türk ihracatçılarının başarısının ardında birkaç faktör bulunmaktadır. Öncelikle, Türk ihracatçıları genellikle yüksek kaliteli ürünler üretmekte ve rekabetçi fiyatlar sunmaktadır. Türk mal ve hizmetleri, çeşitli sektörlerde, özellikle otomotiv, tekstil, makine, savunma sanayi, gıda ve inşaat gibi alanlarda uluslararası alanda takdir görmektedir.',
      'İkinci olarak, Türk ihracatçıları, uluslararası pazarlara erişimi kolaylaştırmak için aktif olarak ticaret fuarlarına, iş gezilerine ve diğer ihracat promosyon etkinliklerine katılmaktadır. Bu etkinlikler, Türk ihracatçılarına yeni müşterilerle bağlantı kurma, potansiyel iş ortaklarıyla görüşme ve ürünlerini tanıtma fırsatı sunmaktadır. Türk ihracatçılarının başarısında üçüncü bir faktör, Türkiye\'nin güçlü lojistik altyapısıdır. Türkiye, stratejik konumu sayesinde Avrupa, Orta Doğu ve Asya arasında bir köprü görevi görmektedir. Limanlar, havaalanları ve kara yollarındaki yatırımlar, Türk ihracatçılarının ürünlerini hızlı ve güvenilir bir şekilde dünya geneline ulaştırmasını sağlamaktadır.',
      'Ayrıca, Türk ihracatçıları, hükümetin ihracatı teşvik eden politikalarından da faydalanmaktadır. Türkiye\'de ihracatı desteklemek için çeşitli teşvik ve teşvik mekanizmaları bulunmaktadır. Bu teşvikler arasında vergi indirimleri, kredi kolaylıkları, ihracat kredileri ve ticari destek programları yer almaktadır. Bu önlemler, Türk ihracatçılarının rekabetçi olmalarını sağlamak ve yeni pazarlara girmelerini teşvik etmektedir.',
      'Sonuç olarak, Türk ihracatçıları, kaliteli ürünler, uluslararası pazarlara erişim, güçlü lojistik altyapı ve hükümetin destekleyici politikaları sayesinde dünya genelinde başarılı bir şekilde faaliyet göstermektedir. Türkiye\'nin ihracatçıları, ülkenin ekonomik büyümesine katkıda bulunarak istihdamı artırmak, teknoloji transferini teşvik etmek ve Türk mal ve hizmetlerini dünya genelinde daha da tanınır hale getirmek için önemli bir rol oynamaktadır.'
    ],
    date: "2023-05-15",
    image: "/assets/Turk-Ihracatci-Firmalar.webp",
  }, {
    title: "Türkiye’de Yatırım İmkanları",
    excerpt: "Türkiye, coğrafi konumu, genç ve dinamik nüfusu, büyük iç pazarı ve güçlü ekonomik potansiyeli ile yatırımcılar için cazip bir yerdir. Ülkenin stratejik konumu, Avrupa, Asya ve ...",
    description: [
      'Türkiye, coğrafi konumu, genç ve dinamik nüfusu, büyük iç pazarı ve güçlü ekonomik potansiyeli ile yatırımcılar için cazip bir yerdir. Ülkenin stratejik konumu, Avrupa, Asya ve Ortadoğu arasında bir köprü görevi görmesini sağlar. Ayrıca, istikrarlı ekonomik büyüme, büyük altyapı projeleri ve reform çabalarıyla Türkiye, yatırımcılar için çeşitli fırsatlar sunmaktadır.',
      'Türkiye\'de yatırım yapmanın bazı avantajları vardır. Bunlardan ilki, büyük ve geniş bir iç pazarın bulunmasıdır. Türkiye\'nin 85 milyonluk bir nüfusu vardır ve tüketici tabanı oldukça büyüktür. Bu da yerli ve yabancı şirketler için potansiyel müşteri kitlesi anlamına gelir. Ayrıca, Türkiye\'nin güçlü bir üretim ve ihracat sektörü bulunmaktadır, bu da yatırımcılar için ihracat imkanlarına erişim sağlar.',
      'Türkiye\'nin altyapı projeleri de yatırım yapma konusunda fırsatlar sunar. Özellikle enerji, ulaşım, sağlık, turizm ve telekomünikasyon gibi sektörlerde büyük projeler yürütülmektedir. Bu projeler, özel sektör yatırımcıları için ortaklık, işbirliği veya alt yüklenici fırsatları sunabilir.',
      'Türkiye, yatırımlara teşvikler sunan bir dizi yatırım teşvik programına sahiptir. Bu teşvikler arasında vergi indirimleri, gümrük muafiyeti, arazi tahsisi, yatırım teşvik belgesi gibi avantajlar bulunmaktadır. Yatırımcılar, yerli veya yabancı sermayeli şirket kurarak bu teşvik programlarından yararlanabilirler. Ayrıca, Türkiye\'deki serbest ticaret bölgeleri, organize sanayi bölgeleri ve teknoloji geliştirme bölgeleri gibi özel bölgeler de yatırımcılar için cazip imkanlar sunmaktadır.',
      'Türkiye\'nin yatırım yapılabilecek sektörler arasında enerji, otomotiv, inşaat, tarım, tekstil, sağlık, turizm, bilgi teknolojileri ve finans gibi alanlar bulunmaktadır. Bu sektörlerdeki büyüme potansiyeli, yatırımcıların Türkiye\'ye ilgi göstermesini sağlamaktadır. Ayrıca, Türk hükümeti, Ar-Ge ve inovasyon odaklı projeleri desteklemekte ve bu alanda yatırım yapanlara teşvikler sunmaktadır.',
      'Yatırım yaparken, Türkiye\'nin yasal çerçevesini, iş düzenlemelerini ve vergi politikalarını anlamak önemlidir. Yerel yasaları takip etmek, iş süreçlerini anlamak ve profesyonel danışmanlık almak, yatırımlarınızın başarılı olması için önemli adımlardır.',
      'Sonuç olarak, Türkiye, yatırımcılar için çeşitli fırsatlar sunan dinamik bir ekonomiye sahiptir. İstikrarlı büyüme, geniş iç pazar, stratejik konum ve yatırım teşvikleri gibi faktörler, Türkiye\'yi yatırım yapmak isteyenler için çekici bir hedef haline getirmektedir. Ancak, yatırım yapmadan önce yerel düzenlemeleri ve pazar koşullarını dikkatlice değerlendirmeniz önemlidir.'
    ],
    date: "2023-05-15",
    image: "/assets/Turkiyede-Yatirim-Imkanlari.webp",
  },
  // Add more blog posts here
];

export const Dots = ({ dots }: any) => {
  return (
    <div className={Style.Dots}><ul> {dots} </ul></div>
  )
}
const Style = {
  Dots: `absolute -bottom-4 right-0 left-0 m-auto flex justify-center w-full
    [&_ul]:bottom-0 [&_ul]:right-5 [&_ul]:flex [&_ul]:w-fit
    [&_button]:w-4 [&_button]:h-4 [&_button]:mr-1 [&_button]:relative [&_button]:!text-[0px] [&_button]:cursor-pointer [&_button]:transition-0.6s [&_button]:border-2 [&_button]:border-[#20b1f5] [&_button]:shadow [&_button]:transition-[0.6s]
    [&_button]:focus:outline-none 
    [&_.slick-active_button]:border-2 [&_.slick-active_button]:border-[#144e85] [&_.slick-active_button]:w-3 [&_.slick-active_button]:h-3 [&_.slick-active_button]:mt-[-2px] [&_.slick-active_button]:transition-[0.6s]`,
  NextArrow: `absolute bottom-[45%] right-0 md:-right-10 left-auto z-10 text-0 p-1 border-none cursor-pointer transition-0.5s bg-transparent
    [&_svg]:w-8 [&_svg]:h-8 [&_svg]:text-[#20b1f5]
    hover:bg-[#ffffff55]
    focus:outline-none`,
  PrevArrow: `absolute bottom-[45%] left-0 md:-left-10 right-auto z-10 text-0 p-1 border-none cursor-pointer transition-0.5s bg-transparent
    [&_svg]:w-8 [&_svg]:h-8 [&_svg]:text-[#20b1f5]
    hover:bg-[#ffffff55]
    focus:outline-none`,
}

export const ArrowPrev = ({ ...props }: any) => {
  return (
    <button {...props} className={Style.PrevArrow}>
      <Icon name="ic:outline-keyboard-arrow-left" />
    </button>
  )
}

export const ArrowNext = ({ ...props }: any) => {
  return (
    <button {...props} className={Style.NextArrow}>
      <Icon name="material-symbols:chevron-right" />
    </button>
  )
}
export default function BlogNews() {
  const [mounted, setMounted] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setMounted(true);
    setIsMobile(window.innerWidth < 768);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <ArrowPrev />,
    nextArrow: <ArrowNext />,
    appendDots: (dots: any) => <Dots dots={dots} />,
  };

  if (!mounted) { return null; }

  return (
    <section className="py-16 ">
      <Container className="flex-wrap">
        <h2 className="text-3xl font-bold text-center mb-12">Blog / Bizden Haberler</h2>
        <div className="block justify-center w-full [&_.slick-track]:!flex [&_.slick-track]:!w-fit">
          <ClientOnly fallback={<div>Loading...</div>}>
            {() => (
              <Slider {...settings} className="pb-4">
                {blogPosts.map((post) => (
                  <a key={post.title} href={`/blog/${slugify(post.title)}`} className="px-2 h-full">
                    <Card className="h-[404px]">
                      <img src={post.image} alt={post.title} className="w-full h-48 object-cover" />
                      <CardHeader>
                        <CardTitle>{post.title}</CardTitle>
                      </CardHeader>
                      <CardContent>
                        <p className="text-sm text-gray-500 font-lato mb-2 flex items-center gap-2">
                          <Icon name="material-symbols:calendar-month" /> {toLocaleDateString(new Date(post.date))}
                        </p>
                        <p className="mb-2 font-lato text-sm text-gray-500">{post.excerpt}</p>
                      </CardContent>
                    </Card>
                  </a>
                ))}
              </Slider>
            )}
          </ClientOnly>
        </div>
      </Container>
    </section>
  );
}